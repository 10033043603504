p{
  margin: 0;
  padding: 0;
}
.header-section {
  width: 100%;
}
.section-2 {
  width: 100%;
  padding: 15px 100px;
  background: #343a40;
  @media screen and (max-width:768px) {
    padding: 15px 50px;
  }
  .header {
    text-align: center;
    color: white;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 25px;
    font-family: 'Oswald',sans-serif;
    
    @media screen and (max-width:768px) {
      font-size: 23px;
    }
  }
  .contain {
    text-align: center;
    color: white;
    font-size: 12px;
    padding: 15px;
  }
}
.section-3 {
  width: 100%;
  padding: 15px 50px;
  // background: #edeff1;
  background:#1F1F21;
  color: #fff;
}
.header-contain {
  width: 100%;
  position: relative;
  text-align: center;
  .header {
    padding: 25px;
    font-size: 56px;
    font-weight: 900;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.2);
    @media screen and (max-width:768px) {
      font-size: 25px;
    }
  }
  .sub-header {
    font-size: 30px;
    margin-top: -70px;
    font-weight: 900;
    text-transform: uppercase;
    color: rgba(10, 10, 10, 1);
    border-bottom: 1px solid #000;
    line-height: 2;
    @media screen and (max-width : 768px ) {
      font-size: 20px;
      margin-top: -40px;
    }
  }
}
.feature-row {
  padding: 50px 0px;
  text-align: -webkit-center;
  .header {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    text-transform: capitalize;
    padding: 15px;
  }
  .contain {
    text-align: center;
    font-size: 10px;
    color: #575353;
  }
}
.img-background {
  background: white;
  width: 150px;
  height: 150px;
  text-align: center;
  border-radius: 15px;
  img {
    // margin-top: 15px;
    padding: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
}
.article-row {
  display: flex;
  padding-top: 50px;
  max-width: 100%;
  overflow: auto;
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .display-contain {
    background: white;
    padding: 15px;
    .header {
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .date { 
      color: #575353;
      font-size: 9px;
      text-transform: uppercase;
    }
    .contain {
      color: #575353;
      font-size: 12px;
      margin-top: 12px;
      height: 140px;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.article-row::-webkit-scrollbar {
  display: none;
}
.column {
  min-width: 300px;
  max-width: 300px;
  margin-left: 10px;
  margin-right: 10px;
}
.section-4 {
  width: 100%;
  padding: 15px 100px;
  background: #51585f;
  @media screen and (max-width: 768px) {
    padding: 15px 0px;
  }
}
.section-4 .header-contain .header {
  color: rgba(255, 255, 255, 0.2);
}
.section-4 .header-contain .sub-header {
  color: rgba(255, 255, 255, 1);
}
.store-btn {
  padding: 50px;
  text-align: center;
  display: flex;
  margin: 0 auto;
  width: 520px;
  @media screen and (max-width:768px) {
    display: block;
    width: 100%;
    padding: 20px;
  }
}
.store-btn button {
  margin-left: 20px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    display: flex;
    // /* margin-right: 20px; */
    align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
}
}
.download-button {
  color: #f8f9fa !important;
  text-decoration: none !important;
  border-color: #f8f9fa;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid #f8f9fa;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}
.download-button img {
  width: 30px;
  height: 30px;
}
.btn-text {
  padding-left: 10px;
  text-align: left;
}
.store-btn .title {
  font-size: 14px;
}
.store-btn .store-name {
  font-size: 22px;
  margin-top: -8px;
}
.contact-us {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.header-contain .sub-header::before{
  left: 50% !important;
}
.sectcontrol{
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #1F1F21;

  // height: 400px;
  // display: flex;
  // margin: auto;
  // width: fit-content;
  // .col-md-6{
  .col-md-6{

  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  background-color: #1F1F21;
  color: #edeff1;
// }
.maintext-area{
  // width: 450px;
  // display: flex;
  // flex-direction: column;
  gap: 15px;
  .main-text{
    font-size: 55px;
    line-height: 1;
    font-family: 'Oswald',sans-serif;
    font-weight: bold;
  }
  .book-btn{
    width: 150px;
    padding: 12px;
    background-color: #8F8F90;
    border:0
  }
}
}
}
@media screen and (min-width:768px) and (max-width:900px) {
    .sect1cnt{
    display: grid;
  
  .sec1{
    
    order:2;
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    .maintext-area{
      width:100% !important;
      
    }
  }
}
.sec2vid{
  max-width: 100% !important;
  padding-bottom: 30px;
}

}
.control{
  // .sect1{
// .row{
  height: 800px;
  // height: 500px;
  display: flex;
  // width:800px;
  margin: auto;
 
  .col-md-6{
    //  @media (max-width: 767px) {
    //   .row {
    //     flex-direction: column;
    //   }
    //   .imgsc{
    //     width: 50%;
    //   }
    
    //   .sec1 {
    //     order: 2 !important;
    //     text-align: center;
    //   }
    
    //   .maintext-area {
    //     margin-top: 20px;
    //     p{
    //       font-size: 1px !important;
    //       padding: 5px !important;
    //     }
    //   }
    // }
    // @media (max-width:768px){
      // width: fit-content;
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #1F1F21;
      color: #edeff1;
    // }
    .maintext-area {
      text-align: justify;
      width: 450px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .main-text{
        font-size: 55px;
        line-height: 1;
        font-family: 'Oswald',sans-serif;
        font-weight: bold;
      }
      .book-btn{
        width: 150px;
        padding: 12px;
        background-color: #8F8F90;
        border:0
      }
    
    }
    .home-img{
      height: 300px;
      // height: 723px;
      width: 300px;
      // width: 580px;
      background-position: center;
      background-size: cover;
    }
  }
}
// .row{
  // .sec1{
    @media (max-width: 767px) {
      .row {
        // flex-direction: column;
        background-color: #1F1F21;
      }
      .imgsc{
        width: 50%;
      }
    
      .sec1 {
        order: 2;
        // text-align: center;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        // margin-right: 12px !important;
        // margin-right: 4px !important;
        // margin-left: 4px !important;
        width: 100%;
      }
    
      .maintext-area {
        margin-top: 20px;
       
      }
      .main-text{
        // font-size: 25px !important;
        font-size: 24px !important;
      }
      .vid{
        width: 350px;
        height: 350px;
        align-items: center;
        margin-left: 6px;
      }
    }
  // }
// }
.card-gap{
  gap: 40px;
}
.section-cards{
  background-color: #000;
  padding-block: 40px;
  .container{
    .sec2head{
  @media screen and (max-width:480px) {
      // padding-left: 20px;
      padding-left: 8px;
    }
  }
  }
  h2{
    color: #fff;
    @media screen and (min-width:480px) and (max-width:900px) {
      font-size: 1.8rem;
    padding-left: 12px;
    }
    @media screen and (max-width:480px) {
      font-size: 1.5rem;
      // padding-left: 8px;

    }
  }
  .card-parent {
    display: flex;
  }
  .card{
    background-color: #28282A;
    flex: 1 1 auto;
  }
  .card-title{
    color: #fff;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: unset;
  }
  .card-text{
    color: #FFFFFFBF;
    // height: 102px;
    // overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 38px;
  }
  .webbtn{
    margin-top: 20px;
    padding-inline: 14px;
  }
  
}
.section3{
  // height: 400px;
  // width:800px;
  margin: auto;
  .placeholder-svg{
    filter:invert(1);
    height: 400px;
  }
  .section3svg{
    filter: invert(1);
    height: 400px;
  }
}

.product{
  color: white;
  background-color: #1F1F21;
  padding-bottom: 60px;
  padding-top: 60px;
}
.section4{
  // height: 400px;
  // width:800px;
  margin: auto;
  color: white;
  background-color: #1F1F21;
}

.row {
  .col-md-6{
    // @media (max-width:768px){
      // width: fit-content;
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #1F1F21;
      color: #edeff1;
    // }
    @media screen and (min-width:768px) and (max-width:900px) {
      
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #1F1F21;
      color: #edeff1;
      padding-right: 29px;
    }
    .maintext-area {
      text-align: justify;
      // width: 450px;
      width: 540px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .main-text{
        // font-size: 60px;
        font-size: 38px;
        line-height: 1;
        @media screen and (min-width:768px) {
        font-size: 28px;
        }
      }
      .book-btn{
        width: 150px;
        padding: 12px;
        background-color: #8F8F90;
        border:0
      }
    }
  }
}
.main-acc{
  display: flex;
  flex-direction: column;
  background-color: #1F1F21;
  padding: 15px;
  padding-bottom: 80px;
  padding-top: 90px;
  @media screen and (max-width:480px) {
    padding-top: 60px;;
    // padding-left: 25px;
    padding-bottom: 60px;
    }

}
.accord-head{
  // width: 655px;
  background-color: #1F1F21;
  
  color: #fff; 
  margin: auto;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // text-align: center;
  
}
.home-acc-head{
  font-size: 3rem;
  font-weight: bold;
  padding-bottom: 7px;
  @media screen and (max-width:480px) {
  font-size: 2rem;
  padding-left: 25px;
  }
  @media screen and (min-width:480px) and (max-width:900px) {
  font-size: 2.5rem;
  }

}
.home-cap{
  font-size: 12px;
}
.accord{
  // background-color: #1F1F21;

 
  .accordion{
    width: 700px;
    @media screen and (max-width:480px) {
      // width: 350px;
      width: 100%;
      // padding-right: 53px;
    }
  // background-color: #1F1F21;

  
  }
}
.btn:focus {
  box-shadow: none;
} 
.card-acc{
    padding: 6px;
  background-color: #1F1F21;
    
   .card-header{
    border-radius: 0;
    margin-bottom: -1px;
    background: linear-gradient(54deg, rgba(255, 120, 114, 1) 14%, rgba(255, 250, 99, 1) 85%);
    border: none;
    img{
      position: relative;
      left: -19px;
      color: #000;
      font-weight: bold;
    }
    h2 {
    .btn-card-acc:focus{
      box-shadow: none;
      outline: 0;
    }
   
  }
      
    /* background: rgb(var(--color-background)); */
    /* background: var(--gradient-background); */
    background-attachment: fixed;
      .text-left{
        color: #1F1F21;
      }
   }
   .card-body{
    border-radius: 0;
    margin-bottom: -1px;
    background: linear-gradient(54deg, rgba(255, 120, 114, 1) 14%, rgba(255, 250, 99, 1) 85%);
    /* background: rgb(var(--color-background)); */
    /* background: var(--gradient-background); */
    background-attachment: fixed;

   }
}

.sec3-area{
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media screen and (max-width:480px) {
    padding: 12px;
    width: 100%;
    

    // padding-right: 20px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
  h2{
    @media screen and (max-width:480px) {
      font-size: 1.8rem;
      // padding: 3px;
    }
  }
  p{
    @media screen and (max-width:480px) {
    padding:8px;
    font-size: small;
    margin: 0;
    gap: 0;
    }
  }
  .para3{
    @media screen and (max-width:480px) {
    padding-bottom: 40px;
    }
  @media screen and (min-width:480px) and (max-width:900px) {
    font-size: 15px;
  }

  }
  .main-text{
    font-size: 55px;
    line-height: 1;
    font-family: 'Oswald',sans-serif;
    font-weight: bold;
  }
  .book-btn{
    width: 150px;
    padding: 12px;
    background-color: #8F8F90;
    border:0
  }

}

.sec2card{
  
  .card{
    .cardvid{
      margin-top: 2px;
      padding-top: 5px;
       display: flex;
       align-items: center;
       justify-content: center;
    @media screen and (max-width:480px) {
      margin-top: 2px;
       display: flex;
       align-items: center;
       justify-content: center;
       width: 100%;
    }
    }
  }
}
@media screen and (max-width:480px) {
  .vid{
    width: 100%;
    height: auto;
    margin-left: 0px;
    padding-top: 7px;
    padding-inline: 6px;
  }
  .vid1{
    width: 100%;
    height: auto;
    margin-left: 0px;
    padding-top: 5px;
    padding-inline: 6px;
  }
  .imgcard{
    padding-top: 7px;
    padding-inline: 6px !important;
    width: 100%;
    // height: auto;
    height: 180px;
  
  }
}
@media screen and (min-width:768px) and (max-width:900px) {
  .vid1{
    // width: 380px !important;
    // width: 373px !important;
    // height: 274px !important;
    // height: 300px !important;
    @media screen and (min-width:900px) {
      width:530px !important;
      height:280px !important
    }
  }
  .vid{
    width: 185px !important;
    height: 180px !important;
    margin-top: -30px;
  }
  .imgcard{
    width: 100%;
    height: 149px;
    padding-inline: 6px;
    padding-top: 12px;
  }
  .intro-para{
    font-size:15px;
  }
  .section-cards{
    .card-title{
      font-size: 15px;
    }
    .card-text{
      font-size: 13px;
      height: 75px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .webbtn{
      margin-top: 12px;
      padding-inline: 14px;
    }
  }
}
@media screen and (min-width:900px) {
  .imgcard{
  padding-top: 22px;
  }

}
.searchbartp{
  @media screen and (min-width:786px) {
    margin-right: 80px;
  }
    .MuiInputBase-input{
      font: caption !important;
      color: black !important;
      font-weight: bold !important;
    }
    @media screen and (max-width:480px) {
      padding-top: 15px;
    }
  
    }

   
    
    .search-tp{
      border:"none";
      height:40px;
      margin-bottom:14px;
      color:"black";
    
      @media screen  and (max-width:480px){
        width:90%;
        margin-left: 16px;
        // border:"none";
        // height:40px;
        // margin-bottom:14px;
        // color:"black";
        // padding-inline: 5px;
      }
    }

  // h5{
    .head-tit{
    @media screen and (max-width:480px) {
     padding-inline: 18px;
    padding-top: 1px;
    }
  }
  // }  
.desk-head{
  @media only screen and (min-width:786px) {

  display: flex;
  justify-content: space-between;
  margin-left: 80px;
}
@media only screen and (min-width:768px) and (max-width:900px) {

  display: flex;
  justify-content: space-between;
  padding-inline: 40px;
  // margin-left: 23px;
}
}
// .league-head{
//   .stats-di{
// .tp-stats{
//   background-color: '#1F1F21';
//   color:"white" !important;
//   margin-left:"75px";
//   margin-bottom:"0px";
//   padding-bottom:"0px";
//   display:"flex" !important;
//   justify-content:"space-between" !important;
  
  // @media screen and (max-width:480px){
    // background-color: '#1F1F21';
    // color:"white" !important;
    // margin-left:"75px";
    // margin-bottom:"0px";
    // padding-bottom:"0px";
    // display:"flex";
    // justify-content:"space-between";
  // }
// } 
//   }
// }   
.contact-form{
    background-color: #1F1F21;
    color: #edeff1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.form-control{
    border-radius: 0;
    padding: 22px;
    background-color: #1F1F21;
    color: #edeff1;
    font-size: large;
    color:whitesmoke;
    margin-block: 9px;
}
.inner-contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    .col-12{
        @media (max-width: 767px){
            padding-inline: 0px !important;
        }
    }
}
.btn-send{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 0;
    cursor: pointer;
    font: inherit;
    font-size: 1.5rem;
    text-decoration: none;
    appearance: none;
    background-color: #fff;
    // padding: 6px 30px 15px 30px;
    // padding: 10px 30px 15px 30px;
    padding: 9px 30px 12px 27px;;
    margin-bottom: 50px;
    border-radius: 1px;
    font-size: 1em;
    margin-left: 13px;
    margin-top: 30px;
    @media screen and (max-width:480px) {
    margin-left: 2px;
    }
}

.contact-head{
    font-size: 4rem;
    font-family:sans-serif;
    font-weight: bold;
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: 'Oswald', sans-serif;
    @media screen and (min-width:768px) {
        padding-left: 12px;
   }
}
.header-option {
  // background: rgba(0, 0, 0, 0.7);
  background: #1F1F21;
  z-index: 1;
  .navbar-nav {
    z-index: 1;
    a {
      color: #fff !important;
      margin-left: 20px;
      text-decoration: none;
      text-transform: uppercase;
      width: fit-content;
      :hover {
        text-decoration: none;
      }
    }
  }
  .mail {
    color: white;
    padding-right: 5px;
    font-size: 14px;
  }
  .mail-info {
    color: #ada6a6;
    font-size: 13px;
  }
  .social-icon {
    width: 25px;
    padding-left: 10px;
    fill: white;
  }
  .company-logo {
    width: 150px;
    height: 50px;
  }
  
  .nav-option {
    text-transform: uppercase;
    color: white !important;
    font-weight: 900;
    font-size: 12px;
    padding: 22px;
    min-width: 100px;
    text-align: center;
  }
}

.sub-header{
  z-index: 1;
}

.header-Fixed{
  // position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
}

.navbar-toggler-icon{
  background: url(../../assets/images/menu.png);
}

.nav-active{
  // text-decoration: underline;
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    90deg,
    rgba(251, 191, 14, 1) 0%,
    rgba(237, 123, 34, 1) 100%
  );
}

// .row{
//   height: 800px;
//   width:800px;
//   .col-md-6{
//     @media (max-width:768px){
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//   }
// }
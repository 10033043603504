.player-list{
    a{
        color: #000;
        text-decoration: none;

    }
    .card{
        font-weight: 600;
        box-shadow: 1px 1px 1px 1px #cccccc;
        margin-bottom: 30px;
        img{
            height: 420px;
            object-fit: cover;
        }
    }
}

.player-scored{
    font-weight: 600;
    font-size: 1.3rem;
    // margin-bottom: 20px;
    padding-bottom: 50px;
    .border{
        border-color: #bdbdbd !important;
        // background: #f1f1f1;
        border-right: none !important;
        &:nth-last-child(1){
            border-right: 1px solid #bdbdbd !important;
        }
    }
}

.player-details{
    h6{
        margin-bottom: 0px !important;
        font-weight: 600;
        color: #000000;
        margin-right: 10px !important;
    }
}

.player-game-table{

    .rdt_TableHeadRow{
        background: #dddddd !important;
        .rdt_TableCol{
            color: #000 !important;
        }
    }
}

.main-profile{
}
.card-profile{
    background: #fff;
    text-align: center;
    padding: 50px 0;
    margin: 50px 0;
    a{
        text-decoration: none;
    }
    img{
        width: 150px;
    }
    p{
        color: #000;
        margin-top: 20px;
        margin-bottom: 0;
        font-size: 26px;

    }
}
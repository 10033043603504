@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Poppins:wght@100&family=Roboto:wght@100;300&display=swap');
.container-fuild{
  padding: 0px !important;
}
.no-data-found {
  margin-top: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #495057;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    text-align: center;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  overflow-wrap: anywhere;
  // width: 100%;
  // position: absolute;
  // /* top: 50%; */
  // left: 50%;
  // transform: translate(-50%, -50%)
}
.mail {
  color: white;
  padding-right: 5px;
  font-size: 14px;
}
.mail-info {
  color: #ada6a6;
  font-size: 13px;
}
.social-icon {
  width: 25px;
  padding-left: 10px;
  fill: white;
}
.company-logo {
  width: 240px;
  height: 70px;
}
.header-option {
  background: rgba(0, 0, 0, 0.7);
}

  .header-option {
    background: rgba(0, 0, 0, 0.7);
  }

  .header-contain .sub-header{
    border-bottom: none !important;
  }
  
  .header-contain {
    .sub-header{
      position: relative;
      &::before{
        transform: skew(-21deg);
        height: 10px;
        width: 55px;
        background: #f39e1d;
        content: '';
        position: absolute;
        bottom: -10px;
      }
    }
  }
.nav-option {
  text-transform: uppercase;
  color: white !important;
  font-weight: 900;
  font-size: 12px;
  padding: 22px;
  min-width: 100px;
  text-align: center;
}
.header-section {
  width: 100%;
  margin-top: -100px;
}
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
body{
  overflow-x: hidden;
}
.container-fuild{
  width: initial;
}
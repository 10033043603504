.header-contain .sub-header::before {
    transform: skew(
-21deg
);
    height: 10px;
    width: 55px;
    background: #f39e1d;
    content: '';
    position: absolute;
    bottom: -5px !important;
    left: 50% !important;
    transform: translate(-64%, -50%) skew(
-20deg
) !important;
@media screen and (max-width: 767px) {
    bottom: -13px !important;
}
}
/* .text-field-component .input-label {
  margin-top: 0;
  color: #595959;
  font-size: 12px;
  letter-spacing: 0.98px;
  line-height: 14px;
  text-transform: uppercase;
}
.text-field-component .input-label.error {
  color: #fc5e34;
}
.text-field-component .form-control {
  box-sizing: border-box;
  border: 1px solid #b2b2b5;
  border-radius: 3px;
  background-color: #ffffff;
  color: #595959;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  word-break: normal;
}
.text-field-component .form-control.disabled {
  color: #252526 !important;
  font-weight: 400;
  pointer-events: none;
  height: 32px;
  background-color: #f7f9fe;
}
.text-field-component .form-control.error {
  border-color: #fc5e34;
} */

.text-field-component .helper-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #595959;
  white-space: nowrap;
  overflow: hidden;
  /* display: block; */
  text-overflow: ellipsis;
  margin-left: 5px;
}
.text-field-component .helper-text.error {
  color: #fc5e34;
}

.footer{
  .footer-option {
    padding: 50px;
    background: #2c2c2c;
    height: 400px;
    @media screen and (max-width:768px) {
      padding: 20px;
      // display: block;
    }
  }
  .last-footer {
    justify-content: space-between;
    color: #c7c7c7;
    font-size: 12px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .text-org {
    color: orange;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;
  }
  .nav-footer-option {
    color: white;
    font-size: 18px;
    // margin-right: 40px;
  }
}

ul{
  padding-left: 0;
  list-style: none;
  a{
    &:hover{

      text-decoration: none;
    }
  }
}

.footer-sec1{
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(54deg, rgba(255, 120, 114, 1) 14%, rgba(255, 250, 99, 1) 85%);
  flex-direction: column;
  padding: 45px;
   .head1{
    font-size: 3rem;
    font-weight: bold;
    padding-bottom: 9px;
    @media screen and (max-width:480px) {
      // font-size: 2.5rem;
      font-size: 1.8rem;
      padding-left: 8px;

    
    }
    @media screen and (min-width:480px) and (max-width:900px) {
      font-size: 2rem;
    }

   }
   .foot-p{
    padding-bottom: 19px;
    @media screen and (max-width:480px) {
      font-size: 1rem;
    
    }
   }
   .email-inp{
    padding: 10px;
    width: 325px;
    @media screen and (max-width:480px) {
      width: 280px;
    
    }
   }
  .form{
    display:flex;
    flex-direction:row;
    
    /* This bit draws the box around it */
    // border:1px solid grey;
  
    /* I've used padding so you can see the edges of the elements. */
    padding:1px;
    :focus-within{
      // outline: 1px solid blue;
    }
  }
   input{
    // background-image: url("../../assets/images/arrow.svg");
    // background-position: center right;
    // background-repeat: no-repeat;
    // background-size: 15px 20px;
    // border: 1px solid black;
    // border: none;
    // outline: none;
    // font-size: 10px;
    flex-grow:2;
    /* And hide the input's outline, so the form looks like the outline */
    border:none;

   
   }
   input:focus {
    outline: none;
  }
   .button{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: none;
   }
.arr-icon{
  position: absolute;
    height: 12px;
    left: -8px;
}
}

.footer-sec2{
  background-color: #1F1F21;
  color: #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  border-bottom: 1px solid gray;
  padding-top: 50px;
  padding-bottom: 110px;
  .col-md-4{
  //  text-align: center;

  }
  @media screen and (max-width:480px) {
    background-color: #1F1F21;
    color: #c7c7c7;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 40px;
    border-bottom: 1px solid gray;
    margin: auto;
    row-gap: 25px;
  
  }
}

.footer-sec3{
  background-color: #1F1F21;
  color: #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  .svgimg{
    padding: 4px;
    width: 45px;
    @media screen and (max-width:480px) {
      // margin-left: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;

      // width: 1080px;
      padding: 3px;
    }
  }
}
.footer-copy{
  background-color: #1F1F21;
  color: #c7c7c7;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 35px;
}

.footer-sec{
  justify-content: space-between;
}
.line{
 
  // background-color: #c7c7c7;
}
.mob-view{
  // @media screen and (max-width:768px) {
  // @media screen and (min-width:480px) {
    @media only screen and (max-width:480px) {
      html{
        width: 100vw;
        overflow-x: hidden;
      }
  // }
}
}
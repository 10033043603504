.custome-pagination {
  text-align: right;
  margin-top: 20px;
  padding: 8px 14px;
}
.custome-pagination ul {
  margin: 2px 0;
  white-space: nowrap;
  margin-left: auto;
}

.custome-pagination li {
  cursor: pointer !important;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.custome-pagination li:focus {
  outline: none;
}

.custome-pagination p {
  padding-top: 0.85em;
  white-space: nowrap;
  display: flex;
}
/* .custome-pagination .pagination-arrow {
  float: right;
} */
.custome-pagination .pagination-arrow .opacity-5 {
  opacity: 0.5;
  cursor: default !important;
}
.custome-pagination .pagination-arrow:focus {
  outline: none;
}

/* .pagination-text{
  margin-left: 0;
  align-items: flex-start;
} */
.custome-pagination .pagination-number:focus {
  outline: none;
  box-shadow: none;
}
.custome-pagination .pagination-dot {
  float: right;
  color: black !important;
  height: 21px;
  font-size: 24px;
  font-weight: 800;
  padding-top: 2px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 2px;
}

.paginate_button {
  cursor: pointer;
  color: black;
}
.paginate_button.disabled {
  cursor: not-allowed;
}

.pagination-number {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007bff;
  border: 1px solid #ccc;
  padding: 10px;
}
.active .pagination-number {
  z-index: 1;
  color: #fff;
  background-color: #2c96cf;
  border-color: #2c96cf;
}
.custom-pagination .pagination-section ul {
  list-style: none;
  display: flex;
}
/* .page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
} */

.pagination .page-item :hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

/* .custome-pagination .page-item:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
} */

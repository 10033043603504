.story-card {
  flex: 1 1 auto;
  overflow: hidden;
  .article-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  img {
    width: 100%;
    
  }
  .display-contain {
    height: 100%;
    background: white;
    padding: 15px;
    .header {
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
      max-width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .date {
      color: #575353;
      font-size: 9px;
    }
    .contain {
      color: #575353;
      font-size: 12px;
      margin-top: 12px;
      height: 55px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 40px;
    }
  }
}

// @include
@import "../../assets/css/style.scss";
.no-data-component {
  margin-top: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #495057;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    text-align: center;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  overflow-wrap: anywhere;
}
.header-league-section {
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  &::before {
  }
}

.league-list {
  margin-top: 50px;
  margin-bottom: 50px;
  h5 {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    @media screen and (max-width:768px) {
        font-size: 14px !important;
    }
  }
  .rdt_TableHead {
    // display: none !important;
    background-color: #000000 !important;
  }
  .rdt_TableBody {
    .rdt_TableRow {
      background: #38444a;
      border-bottom: 1px solid #000000 !important;
      border-top: 1px solid #000000 !important;
      color: #fff !important;
      &:hover {
        color: #000 !important;
        // cursor: pointer;
      }
    }
    img {
      width: 40px;
      height: 45px;
      object-fit: cover;
    }
  }
  button {
    background: rgb(251, 191, 14);
    background: linear-gradient(
      90deg,
      rgba(251, 191, 14, 1) 0%,
      rgba(237, 123, 34, 1) 100%
    );
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    margin-left: 30px;
    border: 0;
    outline: 0;
    display: inline-block;
    transform: skew(-21deg);
    border-radius: 0px !important;
    @media screen and (max-width:768px) {
        margin-left: 15px;
        width: 100px !important;
    }
  }
}
.select-league-dropdown{
  label{
    font-weight: 600;
  }
  select{
    height: 40px;
    transform: skew(-21deg);
    width: 400px;
    margin-left: 20px;
    background: #38444a;
    &:optional{
      color: #eebc0e   !important;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}
.list-league{
  margin-top: 30px;
  margin-bottom: 50px;
  &-title{
    background: #181e21;
    padding: 25px;
    p{
      margin-bottom: 0px;
      color: #fdc70c;
      font-weight: 600;
      font-size: 15px;
      text-transform: uppercase;
      span{
        color: #fff;
      }
    }
  }
  &-container{
    background: #263136;
    color: #fff !important;
    &-title{
      padding: 15px 25px;
      border-bottom: 1px solid #38444a;
    }
    thead{
      tr{
        th{
          color: #fff !important;
          font-weight: 600;
          border: none;
          text-transform: uppercase;
        }
      }
    }
    tbody{
      tr{
        td{
          background: #38444a;
          color: #fff;
          border: none;
          .badge{
            transform: skew(-20deg);
            border-radius: 0px;
            padding: 5px 10px;
            font-size: 15px\;
          }
        }
      }
    }
  }
}

.league-status{
  .MuiTab-textColorPrimary.Mui-selected{
    background: #fdc70c;
    color: #ffffff;
    font-weight: 600;
    background: linear-gradient(
      90deg,
      rgba(251, 191, 14, 1) 0%,
      rgba(237, 123, 34, 1) 100%
    );
  }
  .PrivateTabIndicator-colorPrimary-2{
    display: none;
  }
  .MuiTabs-scrollable{
    background: #181e21;
    button{
      color: #ffffff !important;
      font-weight: 600;
    }
  }
}

.overview{
  background: #38444a;
  padding: 0;
  .MuiBox-root{
    padding: 0;
  }
  &-title{
    padding: 15px 10px;
    background: #263136;
    margin-bottom: 16px;
    p{
      margin-bottom: 0;
      color: #fff;
      padding: 0;
      font-weight: 600;
      text-transform: uppercase ;
    }
  }
  &-table{
    // margin-bottom: 30px;
    input{
      transform: skew(-20deg);
    }
    .rdt_Table{
      .rdt_TableHead{
        border: none !important;
        .rdt_TableHeadRow{
          background: #263136 !important;
          .rdt_TableCol{
            padding-top: 25px;
            padding-bottom: 25px;
          }
        }
      }
      .rdt_TableBody{
        .rdt_TableRow{
          background: #38444a;
          color: #fff !important;
          border-top: none !important;
          // border-bottom: none !important;
          border-left: none !important;
          border-right: none !important;
          &:hover{
            background: #38444a !important;
          }
          
          
        }
      }
    }
  }
}
.row{
  @media screen and (max-width:768px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}
// .header-contain .sub-header::before{
//   left: 4% !important;
//   @media screen and (max-width:768px) {
//       left: 13% !important;
//   }
// }
.card-list-table .rdt_Table .rdt_TableHeadRow{
  background-color: #38444a !important;
  font-weight: 600;
  padding: 18px 0;
  .rdt_TableCol{
    font-size: 20px !important;
    font-weight: 900 !important;
  }
}

.quater-tab{
  header{
    background: #181e21;
  }
  .MuiTab-textColorPrimary.Mui-selected{
    background: #fdc70c;
    color: #ffffff;
    font-weight: 600;
    background: linear-gradient(
      90deg,
      rgba(251, 191, 14, 1) 0%,
      rgba(237, 123, 34, 1) 100%
    );
  }
  .PrivateTabIndicator-colorPrimary-2{
    display: none;
  }
  .MuiTabs-scrollable{
    background: #181e21;
    button{
      color: #ffffff !important;
      font-weight: 600;
    }
  }
}

.league_table_body.MuiPaper-root {
  // @media screen and (max-width:480px) {
  //   padding: 30px !important;
  // }
  background-color: #1F1F21;
  border: 1px solid black;
  // background-color: #333336;
  border-radius: 0;
  & table {
    & thead.MuiTableHead-root {
      & tr {
        & th {
          background-color: black;
          // background-color: #1F1F21;
          color: #FFF;
          font-weight: 600;
         }
      }
      
    }
    & tbody {
      & tr {
        & td {
          .css-1ex1afd-MuiTableCell-root{
            text-align: right;
          }
          color: #FFF;
         }
      }
    }
  }
  .MuiToolbar-root {
    color: #fff;
    & svg {
      fill: #fff;
    }
//     // .no-data-avl{
//    @media screen and (max-width:480px) {
//     padding: 30px !important;
//   // }
// }
}
.MuiToolbar-gutters{
  @media screen and (max-width:480px) {
    padding: 30px ;
  // }
}
@media screen and (min-width:768px) and (max-width:900px) {
  padding: 24px ;
}
}
}
.tableData{
  text-align: center !important;
   button{
    background: linear-gradient(
      90deg,
      rgba(251, 191, 14, 1) 0%,
      rgba(237, 123, 34, 1) 100%
    ) !important; 
    color: #000 !important;
    font-weight: bold !important;
    border: none !important;
    //extra code to make button style
    padding: 0;
    height: 36px;
    font-size: 14px;
    width: 140px;
   }
   .MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
    background-color: #000 !important;
   }
}

.league-head{
    font-size: 3rem;
    // font-family:sans-serif;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Oswald', sans-serif;
    background-color: #1F1F21;
    color: #fff;
    text-align: center;
    @media screen and (max-width:480px) {
      font-size: 2rem;
      padding-bottom: 2px;
    }
    @media screen and (min-width:768px) and (max-width:900px) {
      font-size: 2.5rem;
    }
     
}
.searchbar{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  width:100%;

  @media screen  and (max-width:480px){
  width: 100%;

    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
}
.search{
  border:"none";
  height:40px;
  margin-bottom:14px;
  color:"black";

  @media screen  and (max-width:480px){
    width:100%;
    border:"none";
    height:40px;
    margin-bottom:14px;
    color:"black";
  }
}
.no-data-found{
  color:white;
  font-size: large;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  // text-align: center !important;
   width: 100%;
  position: absolute;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%)
  
}
.no-teams{
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: row;
  // position: relative;
  color:white;
  width: 100%;
  height: 50px;

}
// .search{
//   background-color: #1F1F21;
//   color: #fff;
// }

.searchbar{
.MuiInputBase-input{
  font: caption !important;
  color: black !important;
  font-weight: bold !important;
}
}


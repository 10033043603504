.article-card {
  .article-img {
    width: 100%;
  }
  img {
    width: 100%;
  }
  .display-contain {
    background: white;
    padding: 15px;
    .header {
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
    }
    .date {
      color: #575353;
      font-size: 9px;
      text-transform: uppercase;
    }
    .contain {
      color: #575353;
      font-size: 12px;
      margin-top: 12px;
    }
  }
}

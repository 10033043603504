.faq-container {
  background: #2c2c2c;
  .looking-text {
    color: White;
    text-align: center;
    margin-top: 40px;
  }
  .query-text {
    color: White;
    text-align: center;
    text-transform: uppercase;
  }
  .query-input {
    margin: 10px;
    width: 93%;
    background: #343a40;
    border-color: #343a40;
    color: #fff !important;
    border-radius: 0px !important;
  }
}

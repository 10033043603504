.custom-btn {
    background: rgb(251, 191, 14);
    background: linear-gradient(
      90deg,
      rgba(251, 191, 14, 1) 0%,
      rgba(237, 123, 34, 1) 100%
    );
    color: #000000 !important;
    font-weight: 900 !important;
    text-align: center;
    text-decoration: none;
    margin-left: 30px;
    border: 0;
    outline: 0;
    display: inline-block;
    transform: skew(-21deg);
    border-radius: 0px !important;
  }
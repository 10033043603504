.custom-input {
  transform: skew(-21deg);
  // background: #000 !important
  input {
    background: #ccc !important;
  }
}
.custom-card {
  box-shadow: 0px 0px 10px 8px #ccc !important;
  &-body {
    padding: 15px 20px;
  }
  &.video-card {
    position: relative;
  }
  .video-play-button {
    position: absolute;
    top: 31%;
    left: 40%;
    cursor: pointer;
  }
  h5 {
    font-weight: 700;
    color: #263136 !important;
  }
  span {
    color: #b6b6b6;
  }
}

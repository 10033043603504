.number-text{
    font-size: 100px;
    font-weight: 900;
    color: orange;
}
.white-border {
    width: 80px;
    height: 80px;
    background: white;
    border-radius: 50%;
    margin: auto;
    opacity: 0.5;
}
.description{
    font-size: 12px;
    padding: 14px;
}
.laptop-img{
    width: 350px;
    height: 300px;
}
.mobile-img{
    width: 250px;
    height: 400px;
}
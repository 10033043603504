.title-head{
    font-size: 3rem;
    // font-family:sans-serif;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Oswald', sans-serif;
    background-color: #1F1F21;
    color: #fff;
    text-align: center;
//   @media screen and (min-width:480px) and (max-width:900px) {
  @media screen and (min-width:320px) and (max-width:900px) {
    font-size: 2.5rem;
    padding-bottom: 5px;

    
  }
    
}

.new-article{
    background-color: #1F1F21 !important;
}

.card-article{
    background-color: #1F1F21;
    color: #fff;
}

@media screen and (min-width:768px) and (max-width:900px) {
   
    .viddet{
      width: 260px !important;
      height: 180px !important;
      margin-top: -30px;
    }
}
@media screen and (max-width:480px) {
  .vidimg{
    width: 70%;
    height: 200px;
    margin-left: 0px;
    padding-top: 7px;
    padding-inline: 6px;
  }
}
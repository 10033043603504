.tab-btn{
    width: 100%;
    background:#1f1f21;
    .tab-btn1{
      @media screen and (max-width:480px) {
        // font-size: medium;
        font-size: 0.9rem;
      }
    width: 50%;
    // background: black;
    background:#1f1f21;
    border-bottom: 2px solid orange;
    // border: 0 0 0 1px solid red;
    border-radius: unset;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: white;
    font-size: large;

//       .curr{
//         border-bottom: 2px solid red;
//     border: 0 0 0 1px solid red; 
//   }
//   &:active:focus{
//     background-color: #1f1f21 !important;
//     border: 0 0 0 1px solid red !important; 
//         // border: #1f1f21 !important;
//       }
//   &:focus{
//     background-color: #1f1f21 !important;
//     border: 0 0 0 1px solid red !important; 
//         // border: #1f1f21 !important;
//       }
    }
    .tab-btn2{
      @media screen and (max-width:480px) {
        // font-size: medium;
        font-size: 0.9rem;

      }
        width: 50%;
        // background: black;
        background-color:#1f1f21;
        // border: none;
        border-bottom:0px solid #1f1f21;
        border: 0 0 0 0;
        border-radius: unset;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        color: white;
        font-size: large;


    &:active:focus{
        background-color: #1f1f21 !important;
        border: 0 0 0 1px solid orange !important; 
            // border: #1f1f21 !important;
          }
      &:focus{
        background-color: #1f1f21 !important;
        border: 0 0 0 1px solid orange !important; 
            // border: #1f1f21 !important;
          }
    }
}
.curr{
    border-bottom: 2px solid orange;
  border: 0 0 0 1px solid orange; 
}
.head-sec{
  h5{
    @media screen and (max-width:480px) {
      font-size: 1rem;
    }
  }
}
.PrivateTabIndicator-colorSecondary-4{
    background-color: orange !important;
  }
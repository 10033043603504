.title-head{
    font-size: 3rem;
    // font-family:sans-serif;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Oswald', sans-serif;
    background-color: #1F1F21;
    color: #fff;
    text-align: center;
  @media screen and (min-width:480px) and (max-width:900px) {
    font-size: 2.5rem;
  }
    
}

.new-article{
    background-color: #1F1F21 !important;
    padding-bottom: 20px;
}

.card-article{
    background-color: #1F1F21;
    color: #fff;
}